export const ICONS = {
  1: {
    name: '医保类',
    icon: require('@/assets/img/card/yibao.png'),
  },
  2: {
    name: '社保类',
    icon: require('@/assets/img/card/shebao.png'),
  },
  3: {
    name: '婚姻登记',
    icon: require('@/assets/img/card/minzheng.png'),
  },
  4: {
    name: '市监类',
    icon: require('@/assets/img/card/shijian.png'),
  },
  5: {
    name: '城乡居保类',
    icon: require('@/assets/img/card/chengxiang.png'),
  },
  6: {
    name: '交通类',
    icon: require('@/assets/img/card/jiaotong.png'),
  },
  7: {
    name: '就业失业类',
    icon: require('@/assets/img/card/jiuye.png'),
  },
  8: {
    name: '不动产类',
    icon: require('@/assets/img/card/budongchan.png'),
  },
  9: {
    name: '规划类',
    icon: require('@/assets/img/card/guihua.png'),
  },
  10: {
    name: '住建类',
    icon: require('@/assets/img/card/zhujian.png'),
  },
  11: {
    name: '危化类',
    icon: require('@/assets/img/card/weihua.png'),
  },
}

export const CARDS = [
  {
    id: 1,
    name: '城乡居保类',
    icon: require('@/assets/img/card/chengxiang.png'),
  },
  {
    id: 2,
    name: '交通类',
    icon: require('@/assets/img/card/jiaotong.png'),
  },
  {
    id: 3,
    name: '就业失业类',
    icon: require('@/assets/img/card/jiuye.png'),
  },
  {
    id: 4,
    name: '不动产类',
    icon: require('@/assets/img/card/budongchan.png'),
  },
  {
    id: 5,
    name: '规划类',
    icon: require('@/assets/img/card/guihua.png'),
  },
  {
    id: 6,
    name: '住建类',
    icon: require('@/assets/img/card/zhujian.png'),
  },
  {
    id: 7,
    name: '危化类',
    icon: require('@/assets/img/card/weihua.png'),
  },
  {
    id: 8,
    name: '工改事项',
    icon: require('@/assets/img/card/shijian.png'),
  },
]

export const CODES = {
  1: [
    {
      id: 1,
      name: '城乡居民基本养老保险关系转移接续申请',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/8e69b08b-92cc-4db7-87ab-830159f64c49',
    },
    {
      id: 1,
      name: '城乡居民养老保险参保登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/c1ea05bf-a6ff-42ab-929b-3a053bd2a809',
    },
    {
      id: 1,
      name: '城乡居民养老保险待遇申领',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/b109d207-3bb9-4261-93ff-494b58226b52',
    },
    {
      id: 1,
      name: '城镇职工基本养老保险与城乡居民基本养老保险制度衔接申请',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/d8f4fd8f-a6c3-45d0-bebe-864ccee25e51',
    },
    {
      id: 1,
      name: '居民养老保险注销登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/bcdf6c63-db66-4658-b667-449e0343193f',
    },
  ],
  2: [
    {
      id: 2,
      name: '营运证核发',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/aa1e80fc-2874-11eb-ba6c-fa163ed14ae8',
    },
  ],
  3: [
    {
      id: 3,
      name: '创业补贴申领',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/0f11e944-8f16-4e59-9f75-521bbed9dd92',
    },
    {
      id: 3,
      name: '创业担保贷款申请',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/bacc2b83-6ff1-4d1a-8d70-8de9e9e0035e',
    },
    {
      id: 3,
      name: '创业开业指导',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/b9d92a85-2f6d-4fc3-86b5-52bf61d12600',
    },
    {
      id: 3,
      name: '代缴基本医疗保险费',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/87de39b1-78b6-4bc0-b9ca-79c50d37291a',
    },
    {
      id: 3,
      name: '价格临时补贴申领',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/ce360c5a-5b62-4cc3-ab56-af2c973eeba8',
    },
    {
      id: 3,
      name: '就业困难人员认定',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/a2de7730-e957-4392-ab3b-a5efec25ba79',
    },
    {
      id: 3,
      name: '丧葬补助金和抚恤金申领',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/85ccb7b1-f154-4066-bd15-fdad0b5b781e',
    },
    {
      id: 3,
      name: '失业保险待遇发放账户维护申请',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/31cc391d-a1c4-4aa0-87da-f111846cc717',
    },
    {
      id: 3,
      name: '失业保险金申领',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/8f23343b-d630-4d93-9718-8d36851772e0',
    },
    {
      id: 3,
      name: '职业供求信息、市场工资指导价位信息和职业培训信息发布',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/4ed792e9-5513-47f5-b432-f8122905cf16',
    },
    {
      id: 3,
      name: '职业技能鉴定补贴申领',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/57441ce0-a52f-4335-b4f6-19beed7d0e30',
    },
    {
      id: 3,
      name: '职业介绍',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/9010e7a5-eb9a-40e8-9fc3-c9e7d9e0aa37',
    },
    {
      id: 3,
      name: '职业培训补贴申领',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/d8209375-c46e-4639-acd0-ba740f69b30b',
    },
    {
      id: 3,
      name: '职业指导',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/d8bbcbb8-a91c-44b5-b9c8-e3479ec800eb',
    },
  ],
  4: [
    {
      id: 4,
      name: '查封登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/7ccdb01c-3d2c-4eff-a4f0-0455ecae5a82',
    },
    {
      id: 4,
      name: '权属证书的换发、补发',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/e81a64f6-697d-4eea-90e3-f3dec6101b26',
    },
    {
      id: 4,
      name: '抵押登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/a3a01db4-18fa-4b32-b429-9edc32c2e9c5',
    },
    {
      id: 4,
      name: '二手房买卖',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/4677f21d-0c0b-4240-8c28-922a339bb0cb',
    },
    {
      id: 4,
      name: '更正登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/20248c3c-3015-4841-8547-805884a92196',
    },
    {
      id: 4,
      name: '集体首次',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/7cb6df64-6f65-462a-9895-21ecb15a1e2b',
    },
    {
      id: 4,
      name: '商品房首次登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/a0033940-04cd-4b0f-88ce-516cc381bfb3',
    },
  ],
  5: [
    {
      id: 5,
      name: '建设工程规划许可类核发',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/f45bdab8-e0a5-4f7d-99cd-9e672d9e7629',
    },
  ],
  6: [
    {
      id: 6,
      name: '建筑工程施工许可证核发',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/302f7b9a-0c34-4d4f-88bb-e5bd846667ec',
    },
    {
      id: 6,
      name: '商品房预售许可',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/af5c9e62-2d54-11eb-ba6c-fa163ed14ae8',
    },
  ],
  7: [
    {
      id: 7,
      name:
        '除剧毒化学品、易制爆化学品外其他危险化学品（不含仓储经营）经营许可变更',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/1940eab9-0f7e-4a3c-b188-3e78d81558b3',
    },
  ],
  8: [
    {
      id: 8,
      name: '临时占用城市绿化用地审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/fe931115-2d54-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '临时建设工程规划许可',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/21b9fb67-f84c-4f7a-a10f-04f8dcc2729a',
    },
    {
      id: 8,
      name: '临时用地审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/45024ec1-2c7a-4674-a3bd-d465464eab45',
    },
    {
      id: 8,
      name: '乡村建设规划许可证核发',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/6f02571f-021a-4fcf-861d-1317335a9dbb',
    },
    {
      id: 8,
      name: '书面报告备案',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/5bf7637e-2709-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '其他建设工程消防验收备案',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/86ee05c6-27ae-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '内资项目备案（工业技术改造项目除外）',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/b0328c09-a07a-4fb7-b806-207ba772ca86',
    },
    {
      id: 8,
      name: '利用堤顶、戗台兼做公路审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/75028473-2d56-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '占用、挖掘城市道路',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/ba86660f-2710-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '取水许可----发证',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/99c7399e-2d55-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '取水许可----延续',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/998cf5c3-2d55-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '取水许可---申请',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/99511f18-2d55-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '变更取水许可证其他内容',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/9a72658c-2d55-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '变更取水许可证单位名称或个人名称',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/9a05f4ea-2d55-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '因工程建设需要拆除、改动、迁移供水、排水与污水处理设施审核',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/c078690b-2d54-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '国有农用地的使用权登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/3f355dbe-b032-4173-b081-5bd1dd9b62c5',
    },
    {
      id: 8,
      name: '国有土地使用权（协议）出让、租赁审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/47ff625d-9c29-4941-b8b7-5e6c023be043',
    },
    {
      id: 8,
      name: '国有建设用地使用权划拨批准',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/55de332f-e82d-4e44-a0e1-6b1549ba72e3',
    },
    {
      id: 8,
      name: '在大坝管理和保护范围内修建码头、渔塘许可',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/744c1ca1-2d56-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '坝顶兼做公路审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/75028473-2d56-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '城镇排水与污水处理设施竣工验收备案',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/7ec539f0-2d55-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '城镇污水排入排水管网许可',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/afcbfedd-2d54-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '对建设工程竣工档案的接收审验',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/ea330763-2711-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name:
        '对核发选址意见书、建设用地规划许可证或乡村建设规划许可证时征求意见的确认',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/3cdaf1ea-ae90-11ec-8a97-fa163ed14ae8',
    },
    {
      id: 8,
      name: '工程竣工验收监督',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/ca0e5e97-5a5c-426b-8b56-965d502ad719',
    },
    {
      id: 8,
      name: '建筑工程施工许可证核发、补发、变更',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/302f7b9a-0c34-4d4f-88bb-e5bd846667ec',
    },
    {
      id: 8,
      name: '建设工程规划核验（验收）',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/ab17c74e-7f95-4359-85ac-68f90805029d',
    },
    {
      id: 8,
      name: '建设工程规划许可证变更',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/4af9711d-db2c-4b70-ac1a-bd8baeaba706',
    },
    {
      id: 8,
      name: '建设工程规划许可证延期',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/03bd7d41-0d09-4e5b-a0fc-23857f2c36f2',
    },
    {
      id: 8,
      name: '建设工程规划许可证核发',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/f45bdab8-e0a5-4f7d-99cd-9e672d9e7629',
    },
    {
      id: 8,
      name: '建设用地使用权（土地）--国有建设用地使用权首次登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/dba591ac-8d2c-495a-bfe3-4faa125e5312',
    },
    {
      id: 8,
      name: '建设用地改变用途审核',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/87b20da9-4698-457d-980b-519e8120d3bf',
    },
    {
      id: 8,
      name: '建设用地检查核验',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/d15712d1-65d0-4cad-9b0b-de3434db1fe5',
    },
    {
      id: 8,
      name: '建设用地（含临时用地）规划许可证核发',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/46519233-dc92-4d7b-88f7-fdf48313252b',
    },
    {
      id: 8,
      name: '建设项目环境影响报告书审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/676b58e6-8d19-4ab6-a653-943a78181a2f',
    },
    {
      id: 8,
      name: '建设项目环境影响报告表审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/b2a0fcb0-2042-4693-bf7c-517a73d6ba1f',
    },
    {
      id: 8,
      name: '建设项目用地预审与选址意见书',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/4349b105-dcb2-4332-b908-1baf1d42f201',
    },
    {
      id: 8,
      name:
        '房屋等建筑物、构筑物所有权登记(房地一体)--国有建设用地使用权及房屋所有权首次登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/a2f94a46-eb78-4c4f-be5d-392be1220e3a',
    },
    {
      id: 8,
      name: '政府投资项目初步设计审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/b8ea0397-2df0-476a-87f7-4185caddd80e',
    },
    {
      id: 8,
      name: '政府投资项目建议书审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/5c6a8648-2709-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '文物保护单位建设控制地带内建设工程设计方案审核',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/5db96052-0975-4c7c-b147-b442ceac1e02',
    },
    {
      id: 8,
      name:
        '森林、林木所有权和使用权登记--林地经营权/林木所有权/林木使用权首次登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/aedf3ee7-ec16-41de-93ec-e4405cdcc5b2',
    },
    {
      id: 8,
      name: '江河、湖泊新建、改建或者扩大排污口审核',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/3b40f80c-e95b-4cf0-903b-1308c00603e9',
    },
    {
      id: 8,
      name: '河道管理范围内建设项目工程建设方案审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/74771a2b-2d56-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '河道管理范围内有关活动（不含河道采砂）审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/c80978ce-2d55-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name:
        '海域使用权登记以及其他法定需要的不动产权利登记--海域使用权首次登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/3d48a40e-1b8c-4824-a9b0-2c80e9a0109e',
    },
    {
      id: 8,
      name: '海域使用权设立审核',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/08149fb3-0135-4298-a835-ea77bd39a6dc',
    },
    {
      id: 8,
      name: '海洋工程建设项目环境保护设施竣工验收审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/7d48ecec-4c3b-40da-953d-e92011229251',
    },
    {
      id: 8,
      name: '海洋工程建设项目环境影响报告书审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/727d91f7-6493-439b-89a9-7b9e0cd90f8d',
    },
    {
      id: 8,
      name: '海洋工程建设项目环境影响报告表审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/6c933a84-c1cd-4364-8637-e130063e4c14',
    },
    {
      id: 8,
      name: '生产、储存烟花爆竹建设项目安全设施设计审查',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/bb1430f7-fd06-4624-ad9e-7ea89c739b64',
    },
    {
      id: 8,
      name: '生产建设单位水土保持设施自主验收的备案',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/fcbafdab-2d56-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '生产建设项目水土保持方案审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/c88081a6-2d55-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '砍伐城市树木审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/fedb8885-2d54-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '缴纳防空地下室易地建设费审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/184f82a2-27ae-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '自行招标备案',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/5c0d7d45-2709-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '集体土地所有权登记--首次登记',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/7cb6df64-6f65-462a-9895-21ecb15a1e2b',
    },
    {
      id: 8,
      name: '非防洪建设项目洪水影响评价报告审批',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/c843f6e7-2d55-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '非限制类外商投资项目备案（工业技术改造项目除外）',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/b588cbdf-2703-11eb-ba6c-fa163ed14ae8',
    },
    {
      id: 8,
      name: '项目备案变更',
      url:
        'http://zwfw.jz.gov.cn/jeesaas/f/itemInfo/b5e3faa6-2703-11eb-ba6c-fa163ed14ae8',
    },
  ],
}
