<template>
  <div class="content">
    <div class="pagination">
      <!-- <div id="prev" class="item">上一页</div>
        <div id="next" class="item">下一页</div>
        <div id="back" class="item">返回</div> -->
    </div>
    <div class="cards">
      <div
        class="card"
        v-for="card in cards"
        :key="card.id"
        @click="routeTo(card.id)"
      >
        <img class="icon" :src="card.icon" />
        <span class="name">{{ card.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { CARDS } from '@/constants'

export default {
  name: 'Home',
  data() {
    return {
      cards: CARDS,
    }
  },
  methods: {
    routeTo(id) {
      this.$router.push({
        path: '/qrcode',
        query: { id },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import './content.scss';
</style>
